import { defaultHttpClient } from '@/api/default/defaultHttpClient'

const orderRecommendations = async function (page) {
  try {
    await defaultHttpClient.post('/seller_cabinet/v1/sme_click_order', { page: page || this.$route.meta.title })

    this.$dialog.showSuccess(() => ({
      title: 'Услуга успешно заказана!',
      submitText: 'Замечательно'
    }))
  } catch (err) {
    this.$dialog.showError(() => ({
      title: 'Произошла ошибка!',
      noSubmit: true
    }))
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export default orderRecommendations
