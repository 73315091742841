<template>
  <ProductCard class="add-card">
    <div class="add-card__content d-flex align-center">
      <img
        class="add-card__image mb-5"
        alt=""
        src="@/assets/images/onboarding/shop.svg"
      >
      <h4 class="add-card__text headline-4">
        Добавьте магазин на платформу {{ companyDetails.platformName }}
      </h4>
      <x-btn
        class="add-card__button"
        prepend="plus"
        color="white"
        @click="openAddShopModal"
      >
        Добавить магазин
      </x-btn>

      <add-marketplace-modal />
      <connect-modal @success="onShopConnected" />
    </div>
  </ProductCard>
</template>

<script>
import ProductCard from '@/components/Onboarding/ProductCard'
import AddMarketplaceModal from '@/components/Modals/AddMarketplaceModal'
import ConnectModal from '@/components/Modals/ConnectModal'
import companyDetails from '@/plugins/companyDetails'

export default {
  name: 'AddCard',
  components: { ConnectModal, AddMarketplaceModal, ProductCard },
  data: () => ({
    companyDetails
  }),
  methods: {
    openAddShopModal () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.addShop?.add)
      this.$modal.show('add-marketplace-modal')
    },

    onShopConnected () {
      this.$modal.hide('connect-modal')
      this.$modal.hide('add-marketplace-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add-card
    padding 24px
    background linear-gradient(90deg, #5B40FF 0%, #A040FF 100%)
    &__content
      flex-direction column
      flex-grow 1
      height 100%
    &__text
      color $colors.white
      text-align center
    &__button
      margin-top auto
</style>
