<template>
  <div class="row">
    <div
      v-if="showAddCard"
      class="col col-l-4 col-xl-3 col-xxl-2 mt-3"
    >
      <AddCard />
    </div>
    <div
      v-for="(product, key) in products"
      :key="key"
      class="col col-l-4 col-xl-3 col-xxl-2 mt-3"
    >
      <ProductCard
        :title="product.title"
        :text="product.text"
        :marketplaces="product.marketplaces"
        :button="product.button"
        :type="product.type"
      >
        <template v-if="product.type === 'lead_generation'">
          <div class="lead-generation d-flex align-center p-6">
            <img
              class="mb-5"
              width="100"
              height="100"
              src="@/assets/images/onboarding/order_recommendations.png"
            >
            <div class="white--text headline-5">
              {{ product.text }}
            </div>
            <x-btn
              class="lead-generation__btn"
              color="white"
              :loading="orderLoading"
              @click="order"
            >
              {{ product.button.text }}
            </x-btn>
          </div>
        </template>
      </ProductCard>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/Onboarding/ProductCard'
import AddCard from '@/components/Onboarding/AddCard'
import orderRecommendations from '@/api/default/modules/orderingRecommendations'
import companyDetails from '@/plugins/companyDetails'

export default {
  name: 'Products',
  components: { AddCard, ProductCard },
  data () {
    return {
      orderLoading: false,
      allProducts: [
        /* {
          title: '',
          text: 'Заказать рекомендации по прогнозу поставок и маркетинговым отчетам',
          marketplaces: [],
          button: {
            text: 'Заказать',
            props: {
              href: ''
            },
            metrics: ''
          },
          type: 'lead_generation',
          categories: ['all']
        }, */
        {
          title: 'Единая сводка по магазинам',
          text: 'Получайте ежедневные отчеты по всем подключенным магазинам в “Сводке”',
          marketplaces: ['ozon', 'ali', 'wb', 'sber', 'market'],
          button: {
            text: 'Посмотреть сводку',
            props: {
              href: '/'
            },
            metrics: 'mmp_onboarding_summary'
          },
          type: 'management',
          categories: ['scaling']
        },
        {
          title: 'Анализ категорий',
          text: 'Определите перспективные ниши с помощью "Анализа категорий"',
          marketplaces: ['wb'],
          button: {
            text: 'Проанализировать категории',
            props: {
              href: `${process.env.VUE_APP_ORM_URL}/categories`
            },
            metrics: 'mmp_onboarding_orm_categories'
          },
          type: 'market_analytics',
          categories: ['start', 'scaling']
        },
        {
          title: 'Анализ брендов',
          text: 'Определите перспективные товары с помощью "Анализа брендов"',
          marketplaces: ['wb'],
          button: {
            text: 'Проанализировать бренды',
            props: {
              href: `${process.env.VUE_APP_ORM_URL}/brands`
            },
            metrics: 'mmp_onboarding_orm_brands'
          },
          type: 'market_analytics',
          categories: ['start', 'scaling']
        },
        {
          title: 'Создание магазинов на маркетплейсах',
          text: `Специалисты ${companyDetails.name} бесплатно создадут и запустят магазины на любых маркетплейсах за N дней`,
          marketplaces: ['ozon', 'ali', 'wb', 'sber', 'market'],
          button: {
            text: 'Создать магазины',
            props: {
              href: '/services'
            },
            metrics: 'mmp_onboarding_shops'
          },
          type: 'management',
          categories: ['start', 'scaling']
        },
        {
          title: 'Анализ товаров',
          text: 'Определите товары-лидеры и товары-аутсайдеры в вашем ассортименте с помощью ABC-анализа',
          marketplaces: ['ozon', 'wb'],
          button: {
            text: 'Проанализировать товары',
            props: {
              href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`
            },
            metrics: 'mmp_onboarding_analytics_products_position'
          },
          type: 'market_analytics',
          long: true,
          categories: ['increase', 'scaling']
        },
        {
          title: 'Анализ поисковой выдачи',
          text: 'Проанализируйте позиции товарных карточек в поисковой и категорийной выдаче в динамике и сравните их с товарами-конкурентами',
          marketplaces: ['ozon', 'wb'],
          button: {
            text: 'Проанализировать выдачу',
            props: {
              href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/products`
            },
            metrics: 'mmp_onboarding_analytics_products_competitors'
          },
          type: 'market_analytics',
          long: true,
          categories: ['increase', 'scaling']
        },
        {
          title: 'Оптимизируйте поставки',
          text: 'Узнайте свою упущенную выручку и оптимизируйте остатки и поставки товаров на складах маркетплейсов с помощью отчета по оборачиваемости. Доступно для AliExpress, Ozon и Wildberries.',
          marketplaces: ['ozon', 'ali', 'wb'],
          button: {
            text: 'Получить отчёты',
            props: {
              href: '/product/list'
            },
            metrics: 'mmp_onboarding_productlist'
          },
          type: 'management',
          long: true,
          categories: ['increase', 'scaling']
        },
        {
          title: 'Анализ конкурентов',
          text: 'Усовершенствуйте свои магазины, проанализировав конкурентов с помощью "Анализа конкурентов"',
          marketplaces: ['wb'],
          button: {
            text: 'Найти конкурентов',
            props: {
              href: `${process.env.VUE_APP_ORM_URL}/sellers`
            },
            metrics: 'mmp_onboarding_orm_sellers'
          },
          type: 'market_analytics',
          categories: ['increase', 'scaling']
        },
        {
          title: 'Анализ цен товаров-конкурентов',
          text: 'Проанализируйте цены на товары ваших конкурентов с помощью "Анализа товаров"',
          marketplaces: ['wb'],
          button: {
            text: 'Найти товары-конкуренты',
            props: {
              href: `${process.env.VUE_APP_ORM_URL}/products`
            },
            metrics: 'mmp_onboarding_orm_products'
          },
          type: 'market_analytics',
          categories: ['increase', 'scaling']
        },
        {
          title: 'Мониторинг позиций по товарам',
          text: 'Контролируйте позиции своих товаров в категориях и по ключевым словам. Для общего анализа позиций используйте отчет "Динамика позиций по товарам"',
          marketplaces: ['ozon', 'wb'],
          button: {
            text: 'Проанализируйте позиции',
            props: {
              href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`
            },
            metrics: 'mmp_onboarding_analytics_report_positions'
          },
          type: 'market_analytics',
          categories: ['increase']
        },
        {
          title: 'Мониторинг цен',
          text: 'Контролируйте цены конкурентов с помощью отчета "Сравнение цен с конкурентами". Добавление  производится загрузкой файла, а также путем добавления ссылок в товарной аналитике',
          marketplaces: ['ozon', 'wb'],
          button: {
            text: 'Сравните цены',
            props: {
              href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`
            },
            metrics: 'mmp_onboarding_analytics_report_prices'
          },
          type: 'market_analytics',
          categories: ['increase']
        },
        {
          title: 'Рекомендации по контенту',
          text: 'Получите рекомендации по каждой карточке товара в разделе "Товарная аналитика"',
          marketplaces: ['ozon', 'wb'],
          button: {
            text: 'Получить рекомендации',
            props: {
              href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/products`
            },
            metrics: 'mmp_onboarding_analytics_products_content'
          },
          type: 'market_analytics',
          categories: ['increase']
        },
        {
          title: 'Продвижение и маркетинг',
          text: 'Запустите продажи с помощью "Продвижения и маркетинга"',
          marketplaces: [],
          button: {
            text: 'Скоро',
            props: {
              disabled: true
            }
          },
          type: 'marketing',
          categories: ['increase', 'scaling']
        }
      ]
    }
  },
  computed: {
    showAddCard () {
      const routeName = this.$route.name.split('.')[2]
      return ['all', 'increase', 'scaling'].includes(routeName)
    },
    products () {
      const routeName = this.$route.name.split('.')[2]
      if (routeName === 'all') {
        return this.allProducts
      }
      return this.allProducts.filter((product) => product.categories.includes(routeName))
    }
  },
  methods: {
    async order () {
      this.orderLoading = true
      await orderRecommendations.call(this)
      this.orderLoading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.lead-generation
  height 100%
  flex-direction column
  flex-grow 1
  &__btn
    min-width 168px
    margin-top auto
</style>
