<template>
  <div
    class="product-card"
    :class="cardClasses"
  >
    <div
      v-if="title"
      :class="headClasses"
    >
      <h5>{{ title }}</h5>
    </div>
    <slot>
      <div class="product-card__content">
        <div
          v-if="text"
          class="product-card__content__text"
        >
          {{ text }}
        </div>
        <div
          v-if="marketplaces.length !== 0"
          class="product-card__content__marketplaces"
        >
          <MarketplaceIcon
            v-for="(marketplace, key) in marketplaces"
            :key="key"
            class="product-card__content__marketplaces__icon"
            :marketplace="marketplace"
          />
        </div>
        <x-btn
          v-if="button"
          class="product-card__content__button"
          v-bind="button.props"
          @click="onButtonClick(button.metrics)"
        >
          {{ button.text }}
        </x-btn>
      </div>
    </slot>
  </div>
</template>

<script>
import MarketplaceIcon from '@/components/Onboarding/MarketplaceIcon'

export default {
  name: 'ProductCard',
  components: { MarketplaceIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    marketplaces: {
      type: Array,
      required: false,
      default: () => []
    },
    button: {
      type: Object,
      required: false,
      default: () => null
    },
    type: {
      type: String,
      default: 'cabinet',
      validate: v => ['cabinet', 'management', 'internal_analytics', 'market_analytics', 'marketing', 'full_green'].includes(v)
    }
  },
  data () {
    return {
      icons: ['ozon', 'ali', 'wb', 'sber', 'market']
    }
  },
  computed: {
    headClasses () {
      const classes = ['product-card__head']
      switch (this.type) {
        case 'cabinet':
          classes.push('product-card__head--blue')
          break
        case 'management':
          classes.push('product-card__head--purple')
          break
        case 'internal_analytics':
          classes.push('product-card__head--orange')
          break
        case 'market_analytics':
          classes.push('product-card__head--green')
          break
        case 'marketing':
          classes.push('product-card__head--yellow')
          break
        default:
          return classes
      }
      return classes
    },
    cardClasses () {
      switch (this.type) {
        case 'full_green':
          return 'product-card__full_green'
        case 'lead_generation':
          return 'product-card__lead_generation'
      }
      return null
    }
  },
  methods: {
    onButtonClick (metrics) {
      if (metrics) this.$metrics.hit(metrics, 'mmp_onboarding')
    }
  }
}
</script>

<style lang="stylus" scoped>
.product-card
  display flex
  flex-direction column
  width 100%
  min-height 330px
  background $colors.white
  box-shadow $box-shadows.tile
  border-radius 8px
  overflow hidden
  &__full_green
    background linear-gradient(0deg, rgba(193, 255, 223, 0.2) 0%, #C2FFDF 100%)
  &__lead_generation
    background: linear-gradient(218.5deg, #0FD0DC 0%, #9641FF 100%)
  &__head
    display flex
    align-items center
    height 76px
    padding 0 24px
    &--green
      background linear-gradient(90deg, rgba(193, 255, 223, 0.2) 0%, #C1FFDF 100%)
    &--purple
      background linear-gradient(90deg, rgba(252, 218, 255, 0.2) 0%, #FCDAFF 100%)
    &--blue
      background linear-gradient(90deg, rgba(204, 249, 255, 0.2) 0%, #CCF9FF 100%)
    &--yellow
      background linear-gradient(90deg, rgba(249, 245, 185, 0.2) 0%, #F9F5B9 100%)
    &--orange
      background linear-gradient(90deg, rgba(255, 223, 211, 0.2) 0%, #FFDFD3 100%)
    h5
      font-size 18px
      font-weight 600
  &__content
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding 20px 24px 24px
    &__text
      font-size 14px
      color $colors.gray-darker
    &__marketplaces
      display flex
      margin 8px 0 20px -8px
      &__icon
        margin-left 8px
    &__button
      margin-top auto
      margin-right auto
</style>
