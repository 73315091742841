<template>
  <div
    class="marketplace-icon"
    :style="imageUrl"
  />
</template>

<script>
export default {
  name: 'MarketplaceIcon',
  props: {
    marketplace: {
      type: String,
      default: '',
      validate: v => ['ozon', 'ali', 'wb', 'sber', 'market'].includes(v)
    }
  },
  computed: {
    imageUrl () {
      return {
        backgroundImage: `url(${require(`@/assets/images/onboarding/marketplaces/${this.marketplace}.png`)})`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.marketplace-icon
  background-repeat no-repeat
  background-size cover
  width 24px
  height 24px
  border-radius 2px
</style>
